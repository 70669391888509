/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js"; 

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div>
        <main>{children}</main>
        <div className="footer uk-margin-xlarge-top">
        <footer
          className="uk-container uk-container-small"
          style={{
            marginTop: `2rem`,
          }}
        >
Više o zdravlju redovito pratite na portalu 

          <a href="https://www.centarzdravlja.hr/"> Centar Zdravlja.</a>
        </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
